import { defineStore } from 'pinia';

export const usePaymentStore = defineStore('payment', () => {
  const route = useRoute();

  const paymentData = reactive({
    payment_type: 1,
    account_number: '',
    birth_date: '',
    payment_summ: 0,
    name: '',
    payment_dow: 0,
    phone: '',
    tranInfo: {},
    contractEmail: '',
    contractNumber: '',
    messageId: '',
    messageCode: '',
    account_number_for_message: '',
    curentPayment: {},
    photosArray: [],
    organization_name: '',
    payment_summ_is_blocked: 0,
  });

  const photosArray = ref([]);

  const { $api } = useNuxtApp();

  const getAllPayment = async () => {
    try {
      const { data } = await $api.payment().getPaymentContract({
        payment_type: paymentData.payment_type,
        account_number: paymentData.contractNumber,
        birth_date: paymentData.birth_date,
      });

      Object.assign(paymentData, data.data);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const hasContractData = computed(() => {
    return Object.keys(paymentData.contract).length > 0;
  });

  const postPayContrat = async () => {
    try {
      const dataToSend = {
        account_number: paymentData.contractNumber,
        birth_date: paymentData.birth_date,
        payment_type: paymentData.payment_type,
        payment_summ: paymentData.payment_summ,
        customer_name: paymentData.name,
        customer_phone: paymentData.phone,
        customer_email: paymentData.contractEmail || '',
        payment_dow: paymentData.payment_dow?.index || paymentData.payment_dow,
        navToEndpoint: '/',
        tran_id: route.query.tran_id || null,
      };

      const { data } = await $api.payment().getPaymentUrl(dataToSend);

      if (import.meta.client) {
        setTimeout(() => {
          window.location.replace(data.data.paymentLink);
        });
      }

      paymentData.value = data.data;
    } catch (error) {
      console.log('error postPayContrat', error);
    }
  };

  const setPaymentData = (newData) => {
    paymentData.contractNumber = newData.account_number;
    paymentData.payment_dow = newData.payment_dow;
    Object.assign(paymentData, newData);
  };

  return {
    setPaymentData,
    getAllPayment,
    postPayContrat,
    paymentData,
    hasContractData,
    photosArray,
  };
});
